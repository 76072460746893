import './topbar.css'
import {Search, Person, Chat, Notifications} from '@mui/icons-material'




function Topbar() {
  return (
    <div className="topbarContainer" >
      <div className="topbarLeft">
        <span className="logo">rayiconSocial</span>
      </div>
      <div className="topbarCenter">
        <div className="searchbar">
          <Search className="searchIcon"/>
          <input className="searchinput" placeholder="search for items"/>
        </div>
      </div>
      <div className="topbarRight">
        <div className="topbarLinks">
          <span className="topbarLink">Homepage</span>
          <span className="topbarLink">Timeline</span>
        </div>
        <div className="topbarIcons">
          <div className="topbarIconItem">
            <Person/>
            <span className="topbarIconBadge">1</span>
          </div>
          <div className="topbarIconItem">
            <Chat/>
            <span className="topbarIconBadge">10</span>
          </div>
          <div className="topbarIconItem">
            <Notifications/>
            <span className="topbarIconBadge">24</span>
          </div>
        </div>
        <img src="/assets/person/1.jpeg" alt="" className="topbarimg"/> 
      </div>

    </div>
  )
}

export default Topbar