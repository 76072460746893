import React from 'react'
import Home from './pages/home/Home'



function Counter() {
  return (
    <div className="App">
      <Home />
    </div>
  )
}

export default Counter;